// large
$btn-font-size-lg: 16px;
$btn-line-height-lg: 22px;

// medium
$btn-font-size-md: 14px;
$btn-line-height-md: 20px;

// small
$btn-font-size-sm: 12px;
$btn-line-height-sm: 28px;

// xsmall
$btn-font-size-xs: 10px;
$btn-line-height-xs: 16px;

$fonts: (
    'large' $btn-font-size-lg $btn-line-height-lg,
    'medium' $btn-font-size-md $btn-line-height-md,
    'small' $btn-font-size-sm $btn-line-height-sm,
    'xsmall' $btn-font-size-xs $btn-line-height-xs
);

.typography {
    &-btn {
        @each $name, $size, $leading in $fonts {
            &-#{$name} {
                font: inherit;
                font-weight: 700;
                font-size: $size;
                line-height: $leading;
            }
        }
    }
}

.font-size-100-semibold {
    color: var(--text-color, --gray-900);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.font-size-100-regular {
    color: var(--text-color, --gray-900);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.font-size-75-regular {
    color: var(--text-color, --gray-700);
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
}
.font-size-75-semibold {
    color: var(--gray-700, #747474);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px; /* 150% */
}

.font-size-75-bold {
    --text-color: var(--gray-900, #323232);
    color: var(--text-color);
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
}

.font-size-50-semibold {
    color: var(--gray-700, #747474);
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 140% */
}

.font-size-50-regular {
    --text-color: var(--gray-700, #747474);
    color: var(--text-color);
    font-size: 10px;
    font-weight: 400;
    line-height: 14px; /* 140% */
}
.font-size-400-semibold {
    --text-color: var(--gray-900, #323232);
    color: var(--text-color);
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px; /* 130% */
}

.font-size-200-semibold {
    --text-color: #323232;
    color: var(--text-color, --gray-900);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
}

.font-size-50-bold {
    --text-color: var(--gray-900);
    color: var(--text-color);
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 120% */
    text-transform: uppercase;
}

.font-size-11 {
    --text-color: #747474;
    color: var(--text-color, --gray-700);
    font-size: 11px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
}

.font-size-50-regular-upper {
    --text-color: #959595;
    color: var(--text-color, --gray-600);
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-transform: uppercase;
}
